import type { FlowEditorSDK, AppManifestBuilder } from '@wix/yoshi-flow-editor';
import ActionsId from './utils/ActionsId';

export function configureManifest(
  appManifestBuilder: AppManifestBuilder,
  editorSDK: FlowEditorSDK,
  t: any,
) {
  appManifestBuilder.configureManagementActions(
    async (managementActionsBuilder) => {
      managementActionsBuilder.mainActions().addAction(
        {
          title: t('business.mainAction1'),
          icon: 'addQuestionFaq',
          actionId: ActionsId.ADD_QUESTION,
        },
        {
          title: t('business.mainAction2'),
          icon: 'manageFaq',
          actionId: ActionsId.MANAGE_FAQS,
        },
      );

      managementActionsBuilder.customActions().addAction(
        {
          title: t('business.customAction1'),
          icon: 'settinsFaq',
          type: 'editorActions',
          actionId: ActionsId.CUSTOMIZE_DISPLAY,
        },
        {
          title: t('business.customAction2'),
          icon: 'settinsFaq',
          type: 'editorActions',
          actionId: ActionsId.CUSTOMIZE_LAYOUT,
        },
        {
          title: t('business.customAction3'),
          icon: 'designTabFaq',
          type: 'editorActions',
          actionId: ActionsId.CUSTOMIZE_DESIGN,
        },
      );

      managementActionsBuilder.learnMoreAction().set({
        id: 'ec0d37f5-af0e-4254-9d5a-183ebd5d0a8',
      });
    },
  );
}
